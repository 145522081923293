<template>
  <div class="my-6 relative">
    <div class="space-y-2">
      <InputWithError
          v-model="value.customerName"
          id="name"
          name="name"
          type="text"
          autocomplete="name"
          :label="$t('installation.customerName')"
          :placeholder="$t('installation.customerNamePlaceholder')"
          :errorText="$t('template.reqField')"
          :validation="v$.value.customerName"
      />
      <InputWithError
          v-model="value.customerCompany"
          id="company"
          name="company"
          type="text"
          autocomplete="company"
          :label="$t('installation.companyName')"
          :placeholder="$t('installation.companyNamePlaceholder')"
          @change="dataChanged"
          :validation="v$.value.customerCompany"
      />
      <InputWithError
          v-model="value.customerAddress"
          id="address"
          name="address"
          type="text"
          autocomplete="address"
          :label="$t('installation.address')"
          :placeholder="$t('installation.street')"
          @change="dataChanged"
          :validation="v$.value.customerAddress"
      />
      <InputWithError
          v-model="value.customerCity"
          id="city"
          name="city"
          type="text"
          autocomplete="city"
          :label="$t('installation.addressContinued')"
          :placeholder="$t('installation.city')"
          @change="dataChanged"
          :validation="v$.value.customerCity"
      />
      <InputWithError
          v-model="value.customerZipcode"
          id="post_code"
          name="post_code"
          type="text"
          autocomplete="zip code"
          :label="$t('installation.zipCode')"
          placeholder="00-000"
          @change="dataChanged"
          :validation="v$.value.customerZipcode"
      />
      <InputWithError
          v-model="value.customerPhone"
          id="phone"
          name="phone"
          type="tel"
          autocomplete="phone"
          :label="$t('installation.phoneNumber')"
          placeholder="+48000000000"
          @change="dataChanged"
          :validation="v$.value.customerPhone"
      />
      <InputWithError
          v-model="value.customerEmail"
          id="email"
          name="email"
          type="email"
          autocomplete="email"
          label="Email"
          :placeholder="$t('installation.emailPlaceholder')"
          @change="dataChanged"
          :validation="v$.value.customerEmail"
      />
      <InputWithError
          v-model="value.installationDate"
          id="date"
          name="date"
          type="date"
          autocomplete="date"
          :label="$t('installation.installationDate2')"
          @change="dataChanged"
          :validation="v$.value.installationDate"
      />
    </div>
  </div>
</template>

<script>
import InputWithError from '@/components/inputs/InputWithError.vue';
import useVuelidate from '@vuelidate/core'
import { required, helpers, email } from '@vuelidate/validators'
import { mapState } from 'vuex'

const phone_format = (value) => {return /^\+?[1-9]\d{1,14}$/.test(value)}
const post_format = (value) => {return /^\d{2}(?:[-\s]\d{3})?$/.test(value) }
const max_date = (value) => {return new Date(value).getTime() <= new Date().getTime()}

export default {
    components: {
      InputWithError,
    },
    emits: ['update:modelValue', 'validate', 'data-change'],
    setup () {
      return { v$: useVuelidate() }
    },
    props: {
      modelValue: Object
    },
    data () {
      return {
        value: {
          customerEmail: '',
          customerName: '',
          customerCompany: '',
          customerAddress: '',
          customerCity: '',
          customerZipcode: '',
          customerPhone: '',
          installationDate: new Date().toLocaleString('sv').slice(0,10)
        }
      }
    },
    validations () {
      return {
        value: {
          customerEmail: {
              //required: helpers.withMessage('To pole jest wymagane', required),
              email: helpers.withMessage('Wprowadź poprawny adres email', email)
          },
          customerName: { required: helpers.withMessage(`${this.$t("template.reqField")}`, required) },
          customerCompany: {
            // required: helpers.withMessage('To pole jest wymagane', required)
          },
          customerAddress: { required: helpers.withMessage(`${this.$t("template.reqField")}`, required) },
          customerCity: { required: helpers.withMessage(`${this.$t("template.reqField")}`, required) },
          customerPhone: {
              required: helpers.withMessage(`${this.$t("template.reqField")}`, required),
              phone_format: helpers.withMessage(`${this.$t("template.phoneNumberForm")}`, phone_format)
          },
          customerZipcode: {
              required: helpers.withMessage(`${this.$t("template.reqField")}`, required),
              post_format: helpers.withMessage(`${this.$t("template.zipCodeForm")}`, post_format)
          },
          installationDate: {
              required: helpers.withMessage(`${this.$t("template.reqField")}`, required),
              max_date: helpers.withMessage(`${this.$t("template.futureDate")}`, max_date)
          },
        }
      }
    },
    computed: {
      ...mapState(['user'])
    },
    methods: {
      validate () {
        this.v$.$touch()
        if (this.v$.$error) this.$emit('validate', false)
        else this.$emit('validate', true)
      },
      dataChanged() {
        const data = {
          customerName: this.name,
          customerCompany: this.company,
          customerAddress: this.address,
          customerCity: this.city,
          customerZipcode: this.post_code,
          customerEmail: this.email,
          customerPhone: this.phone_number,
          installationDate: new Date(this.date).toLocaleString('sv').slice(0,10),
        }
        this.$emit('data-change',data)
      }
    },
    watch: {
      v$: {
        deep: true,
        handler () {
          console.log(this.v$.$errors.length)
          if (this.v$.$errors.length) this.$emit('validate', false)
          else this.$emit('validate', true)
        }
      },
      modelValue: {
        deep: true,
        immediate: true,
        handler () {
          this.value = this.modelValue
        }
      },
      value: {
        deep: true,
        handler () {
          this.$emit('update:modelValue', this.value)
        }
      },
    }
}
</script>
