<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" static class="fixed inset-0 overflow-hidden z-50" @close="open = false" :open="open">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
            <div class="w-screen max-w-md">
              <div class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                <div class="min-h-0 flex-1 flex flex-col py-6 overflow-y-scroll">
                  <div class="px-4 sm:px-6">
                    <div class="flex items-start justify-between">
                      <DialogTitle class="text-lg font-medium text-gray-900">
                        {{$t("installation.investorData")}}
                      </DialogTitle>
                      <div class="ml-3 h-7 flex items-center">
                        <button class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-ifgreen" @click="open = false">
                          <span class="sr-only">Close panel</span>
                          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="mt-6 relative flex-1 px-4 sm:px-6">
                    <div>
                      <CustomerDataForm ref="form" v-model="data" @validate="validate" />
                      <div v-if="!['admin','superadmin'].includes(role.name)" class="mt-2">
                          <label for="note" class="block text-sm font-medium text-gray-700">
                            {{$t("notes")}}
                          </label>
                          <div class="mt-1 relative">
                            <textarea
                              id="note"
                              name="note"
                              class="appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-ifgreen focus:border-ifgreen sm:text-sm"
                              v-model="note"
                            />
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex-shrink-0 px-4 py-4 flex justify-end">
                  <button type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" @click="open = false">
                    {{$t("cancel")}}
                  </button>
                  <button @click="submitInstallation" type="submit" class="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-ifgreen hover:bg-ifgreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen">
                    {{$t("save")}}
                  </button>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import CustomerDataForm from '@/components/installations/CustomerDataForm.vue'
import { api, patchHeaders } from "@/assets/js/api-client.js";
import { mapState } from 'vuex'

export default {
    components: {
        Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        CustomerDataForm,
        XMarkIcon,
    },
    props: {
      installation: {
        type: Object,
        required: false
      },  
      method: {
        type: String,
        default: "post"
      }
    },
    data () {
        return {
            open: false,
            data: {},
            note: '',
            validated: true
        }
    },
    computed: {
      ...mapState(['currentInstallation', 'user', 'role', 'organisation'])
    },
    methods: {
        show () {
            this.open = true
            this.data = {
              customerEmail: this.installation ? this.installation.customerEmail : '',
              customerName: this.installation ? this.installation.customerName : '',
              customerCompany: this.installation ? this.installation.customerCompany : '',
              customerAddress: this.installation ? this.installation.customerAddress : '',
              customerCity: this.installation ? this.installation.customerCity : '',
              customerZipcode: this.installation ? this.installation.customerZipcode : '',
              customerPhone: this.installation ? this.installation.customerPhone : '',
              installationDate: this.installation ? new Date(this.installation.installationDate).toLocaleString('sv').slice(0,10) : new Date().toLocaleString('sv').slice(0,10)
            }
            this.note = this.installation ? this.installation.note : ''
        },
        validate(value) {
          this.validated = value
        },
        submitInstallation () {

          this.$refs.form.validate()
          if (!this.validated) return

          let data = this.data
          data['remarks'] = this.note

          const url = this.method == "post" ? '/installations' : `/installations/${this.installation.id}`
          const headers = this.method == "post" ? {} : patchHeaders.headers

          if (this.method === 'post') {
            data['status'] = 'no_device'
            data['organisation'] = `/organisations/${this.organisation}`
            if (this.user.asp) data['receivers'] = [`/users/${this.user.asp.id}`]
          }

          this.$nextTick(() => { this.$store.dispatch('setLoading', true) })

          api.request({method: this.method, url, data, headers}).then(result => {
            this.$store.dispatch('saveCurrentInstallation', result.data)
            console.log(result.data)
            this.$store.dispatch('getCollection', {entity: 'installations'})
            if (['admin', 'superadmin', 'ASP'].includes(this.role.name) && this.installation && this.installation.status != "no_device") {
              this.$store.dispatch('getCollection', {entity: 'installations', status: this.installation.status})
            }
            this.$store.dispatch('setLoading', false)
            this.method === 'patch' ? this.open = false : this.$router.push({path: `/installations/${result.data.id}`})
          }).catch((error) => {
            console.log(error)
            this.$store.dispatch('setLoading', false)
            this.$notify({
              title: error.message,
              text: error.response.data.detail,
              type: 'error',
              duration: 5000
            })
          })
        },
    }
}
</script>
