<template>
  <div class="relative" v-if="currentInstallation !== null">
    <div v-if="typeof currentInstallation === 'undefined'" class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-2">
      {{$t("public.infos.noInstallationText1")}} {{$route.params.id}} {{$t("public.infos.noInstallationText2")}}
    </div>
    <div v-else class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 border-b border-gray-200 bg-white z-10 sticky" :style="{top: '64px'}">
      <div class="">
        <nav class="flex justify-between md:space-x-8 md:justify-start" aria-label="Tabs">
          <a
            v-for="tab in tabs"
            :key="tab.name"
            :id="tab.slug"
            :class="[(currentTab === tab.slug) ? 'border-ifgreen text-ifgreen' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm']"
            @click="changeTab(tab)"
            >
            {{ tab.name }}
          </a>
        </nav>
      </div>
    </div>
    <!-- Instalacje -->
    <div class="max-w-7xl mx-auto sm:px-6 lg:px-8 py-2">
      <div v-if="currentTab === tabs[0].slug" class="px-4">
        <div class="fixed bottom-4 right-4 z-10">
          <button v-on:click="openModal" type="button" class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-ifgreen hover:bg-ifgreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen">
              {{$t("edit")}}
              <PencilIcon class="h-5 w-5 ml-2" />
          </button>
        </div>
        <div class="flex flex-col space-y-3 py-2">
          <div v-for="(entry, index) in installationData" :key="index">
            <p class="text-sm font-medium text-gray-900">
              {{ entry.value }}
            </p>
            <p class="text-xs text-gray-500 truncate">
              {{entry.label}}
            </p>
          </div>
        </div>
        <div v-if="currentInstallation.installer.id != user.id" class="mt-6">
          <p class="text-xs text-gray-500 truncate">
            {{$t("installation.installationSharedBy")}}:
          </p>
          <p class="text-sm font-medium text-gray-900">
            {{ currentInstallation.installer.name }} ({{ currentInstallation.installer.company }})
            <a :href="`mailto: ${currentInstallation.installer.email}`" class="font-normal flex items-center">
              <EnvelopeIcon class="w-3 h-3 mr-1" /> {{ currentInstallation.installer.email }}
            </a>
            <a :href="`tel: ${currentInstallation.installer.phoneNumber}`" class="font-normal flex items-center">
              <PhoneIcon class="w-3 h-3 mr-1" /> {{ currentInstallation.installer.phoneNumber }}
            </a>
          </p>
        </div>
      </div>
      <!-- Urządzenia -->
      <div v-if="currentTab === tabs[1].slug" class="px-4">
        <InstallationDevice v-if="currentInstallation" :installation="currentInstallation" />
      </div>
      <!-- Przeglądy -->
      <div v-if="currentTab === tabs[2].slug">
        <p class="p-4 text-sm" v-if="currentInstallation.status !== 'accepted'">
          {{$t("installation.noInspectionsScheduled")}}.
          <br />
          <br />
          ({{$t("installation.warrantyInspection")}}.)
        </p>
        <Inspections
          v-else
          :installationId="currentInstallation.id"
          :shortened="true"
          stickyTop="119px"
        />
      </div>
      <!-- Serwisy -->
      <div v-if="currentTab === tabs[3].slug">
        <p class="p-4 text-sm" v-if="currentInstallation.status !== 'accepted' && currentInstallation.status !== 'no_warranty'">
          {{$t("installation.noServiceYet")}}.
          <br />
          <br />
          ({{$t("installation.serviceAfterVerification")}}.)
        </p>
        <div v-else>
          <div v-if="currentInstallation.status === 'accepted' || currentInstallation.status === 'no_warranty'" class="fixed bottom-4 right-4 z-10">
            <button v-on:click="addService" type="button" class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-ifgreen hover:bg-ifgreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen">
                {{$t("installation.performService")}}
                <CogIcon class="h-5 w-5 ml-2" />
            </button>
          </div>
          <Services
            :installationId="currentInstallation.id"
            :shortened="true"
            stickyTop="119px"
          />
        </div>
      </div>
    </div>
  </div>
  <div v-else class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-2">
    {{$t("public.infos.noInstallationText1")}} {{$route.params.id}} {{$t("public.infos.noInstallationText2")}}
  </div>
  <div class="hidden">{{installation}}</div>
  <EditInstallation v-if="currentInstallation"
    ref="editinst"
    :installation="currentInstallation"
    method="patch"
  />
</template>

<script>
    import EditInstallation from '@/components/installations/EditInstallation.vue'
    import BarcodeScanner from '@/components/inputs/BarcodeScanner.vue'
    import NativeSelect from '@/components/inputs/NativeSelect.vue';
    import Inspections from '@/components/inspections/Inspections.vue'
    import Services from '@/components/services/Services.vue'
    import InstallationDevice from '@/components/installations/InstallationDevice.vue'
    import Modal from '@/components/layout/Modal.vue'
    import { EnvelopeIcon, PhoneIcon, PencilIcon, CogIcon } from "@heroicons/vue/24/outline"
    import { mapState } from 'vuex'
    import { api } from "@/assets/js/api-client.js";
    import { attachTemplate } from '@/assets/js/helpers.js'

    export default {
      components: {
        EditInstallation,
        Inspections,
        Services,
        InstallationDevice,
        PencilIcon,
        CogIcon,
        EnvelopeIcon,
        PhoneIcon,
        BarcodeScanner,
        NativeSelect,
        Modal
      },
      data(){
        return{
          tabs: [
            { name: `${this.$t("home.data")}`, slug: 'info'},
            { name: `${this.$t("home.device")}`, slug: 'device'},
            { name: `${this.$t("home.inspections")}`, slug: 'inspections'},
            { name: `${this.$t("home.services")}`, slug: 'services'}
          ],
          currentTab: 'info'
        }
      },
      async created () {
        let installation = this.installations.data && this.installations.data.find((inst) => {return inst.id === parseInt(this.$route.params.id)})
        if (!installation) {
          this.$store.dispatch('setLoading', true)
          try {
            const response = await api.get(`/installations/${this.$route.params.id}`)
            installation = response.data
          } catch (error) {
            console.log(error)
          }
          this.$store.dispatch('setLoading', false)
        }
        if (installation) {
          this.$store.dispatch('saveCurrentInstallation', installation)
        }
      },
      computed: {
        ...mapState(['user', 'installations','currentInstallation', 'device', 'inspections', 'services', 'deviceTemplates']),
        installationData () {
          if (this.currentInstallation) {
            return [
              {label: `${this.$t("installation.customerName")}`, value: this.currentInstallation.customerName},
              {label: `${this.$t("installation.companyName")}`, value: this.currentInstallation.customerCompany},
              {label: `${this.$t("installation.address")}`, value: this.currentInstallation.customerAddress},
              {label: `${this.$t("installation.addressContinued")}`, value: this.currentInstallation.customerCity},
              {label: `${this.$t("installation.zipCode")}`, value: this.currentInstallation.customerZipcode},
              {label: `${this.$t("installation.phoneNumber")}`, value: this.currentInstallation.customerPhone},
              {label: "Email", value: this.currentInstallation.customerEmail},
              {label: `${this.$t("installation.installationDate2")}`, value: new Date (this.currentInstallation.installationDate).toLocaleDateString('pl-PL')},
              {label: `${this.$t("installation.notes")}`, value: this.currentInstallation.remarks},
            ].filter( entry => { return entry.value })
          } else {
            return []
          }
        },
        installationDevice () {
          return attachTemplate(this.currentInstallation.customDevice)
        }
      },
      methods: {
        openModal(){
          api.get('/user').then(() => {
            return this.$refs.editinst.show()
          }).catch(() => {
            this.$notify({
              title: "Brak połączenia z internetem",
              text: "Edycja instalacji w trybie offline nie jest możliwa.",
              type: 'error',
              duration: 5000
            })
          })
        },
        changeTab(tab){
          this.currentTab = tab.slug
          this.$router.push({ hash: '#'+this.currentTab})
        },
        addService() {
          this.$router.push({ path:`/services/new/device/${this.installationDevice.id}`})
        }
      },
      watch: {
        $route: {
          immediate: true,
          handler() {
            if(this.$route.hash === '#info'){
              return this.currentTab = this.tabs[0].slug
            }
            else if(this.$route.hash === '#device'){
              return this.currentTab = this.tabs[1].slug
            }
            else if(this.$route.hash === '#inspections'){
              return this.currentTab = this.tabs[2].slug
            }
            else if(this.$route.hash === '#services'){
              return this.currentTab = this.tabs[3].slug
            }
          },
        }
      }
    }
</script>

<style scoped lang="scss">
  .min-full-screen{
    min-height: calc(100vh - 194px);
  }
</style>
